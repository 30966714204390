import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  suppliers: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    getSupplierSuccess: (state, { payload }) => {
      state.suppliers = payload;
      state.loading = false;
    },
    startLoadingSupplier: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllSupplier = ({ accessToken, page }) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
     
      dispatch(slice.actions.startLoadingSupplier());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-supplier`,
        { headers }
      );
      dispatch(slice.actions.getSupplierSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

export const getSupplier = async ({ accessToken }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}/get-all-supplier`,
    { headers }
  );
  return res.data;
};
