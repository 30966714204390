import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  userTrash: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  empTrash: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  tenantTrash: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  fileTrash: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'trash',
  initialState,
  reducers: {
    getUserTrashSuccess: (state, action) => {
      state.userTrash = action.payload;
      state.loading = false;
    },
    getEmpTrashSuccess: (state, action) => {
      state.empTrash = action.payload;
      state.loading = false;
    },
    getTenantTrashSuccess: (state, action) => {
      state.tenantTrash = action.payload;
      state.loading = false;
    },
    getFileTrashSuccess: (state, action) => {
      state.fileTrash = action.payload;
      state.loading = false;
    },
    startLoadingEmp: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getUserTrash = ({ accessToken, page }) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingEmp());
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get-all-userTrash`, { headers });
      dispatch(slice.actions.getUserTrashSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

export const getEmpTrash = ({ accessToken, page }) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingEmp());
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get-all-empTrash?page=${page}`, { headers });
      dispatch(slice.actions.getEmpTrashSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

export const getTenantTrash = ({ accessToken, page }) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingEmp());
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get-all-tenantTrash?page=${page}`, { headers });
      dispatch(slice.actions.getTenantTrashSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

export const getFileTrash = ({ accessToken, page }) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingEmp());
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get-all-fileTrash?page=${page}`, { headers });
      dispatch(slice.actions.getFileTrashSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};
