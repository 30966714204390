import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const UnauthorizedAccess = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '60vh',
          textAlign: 'center'
        }}
      >
        <LockIcon sx={{ fontSize: 60, color: 'action.active', mb: 2 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          Unauthorized Access
        </Typography>
        <Typography variant="subtitle1">
          You haven't been authorized to access this page.
        </Typography>
      </Box>
    </Container>
  );
};

export default UnauthorizedAccess;
