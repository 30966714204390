import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    departments: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "department",
  initialState,
  reducers: {
    getDepartmentSuccess: (state, { payload }) => {
      state.departments = payload;
      state.loading = false;
    },
    startLoadingDepartmet: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllDepartments = ({ accessToken, page }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingDepartmet());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-departments`,
        { headers }
      );
      dispatch(slice.actions.getDepartmentSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

// export const getDoctors = async ({ accessToken }) => {
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: accessToken,
//   };
//   const res = await axios.get(
//     `${process.env.REACT_APP_BACKEND_API}/get-all-doctors`,
//     { headers }
//   );
//   return res.data;
// };
