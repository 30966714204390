import PropTypes from "prop-types";
import createAvatar from "utils/createAvatar";
import Avatar from "components/Avatar";

// ----------------------------------------------------------------------
UserAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.string,
};

export default function UserAvatar({ name, photo, ...other }) {
  return (
    <Avatar
      src={photo}
      alt={name}
      color={photo ? "default" : createAvatar(name).color}
      {...other}
    >
      {createAvatar(name).name}
    </Avatar>
  );
}
