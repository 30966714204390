import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  auditStages: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "auditStage",
  initialState,
  reducers: {
    getAuditStageSuccess: (state, { payload }) => {
      state.auditStages = payload;
      state.loading = false;
    },
    startLoadingAuditStage: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllAuditStages = ({ accessToken, page }) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingAuditStage());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-auditStages`,
        { headers }
      );
      dispatch(slice.actions.getAuditStageSuccess(res.data));
       
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};
