import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import ThemeProvider from "theme";
import ScrollToTop from "components/ScrollToTop";
import { BaseOptionChartStyle } from "components/chart/BaseOptionChart";
import Router from "routes/routes";
import Settings from "components/settings";
import ThemePrimaryColor from "components/ThemePrimaryColor";
import { ProgressBarStyle } from "components/LoadingScreen";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useSelector } from "react-redux";
import defaultLogo from "assets/icon.png";

import useAuth from "hooks/useAuth";
import IdleTimeOutHandler from "components/IdleTimeOut";
import ErrorBoundary from "./ErrorBoundary";

const queryClient = new QueryClient();

function App() {
  const { user } = useAuth();
  const { systemInfo } = useSelector((state) => state.systemInfo);
  const logo = systemInfo?.data[0]?.logo;
  const systemLogo = document.getElementById("systemLogo");
  systemLogo.href = defaultLogo;

  const [isActive, setIsActive] = useState(true);
  const [isLogout, setLogout] = useState(false);

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          {user?._id && systemInfo?.data[0]?.autoLock && (
            <IdleTimeOutHandler
              onActive={() => {
                setIsActive(true);
              }}
              onIdle={async () => {
                setIsActive(false);
                // await dispatch(reset());
              }}
              onLogout={() => {
                setLogout(true);
              }}
            />
          )}
          <ScrollToTop />
          <ThemePrimaryColor>
            <BaseOptionChartStyle />
            <ProgressBarStyle />
            <Settings />
            <Router />
            <Toaster
              gutter={8}
              position="top-center"
              reverseOrder={false}
              toastOptions={{
                // Define default options
                className: "",
                duration: 6000,
               
              }}
            />
          </ThemePrimaryColor>
        </ThemeProvider>
        {process.env.NODE_ENV !== "development" && (
          <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
        )}
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
