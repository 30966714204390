import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Container, Typography } from "@mui/material";
// layouts
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
// components
import Page from "components/Page";
//
import { SentIcon } from "assets";
import TwoStepAuthenticationForm from "./TwoStepAuthForm";
import { useDispatch } from "react-redux";
import { loginUser } from "redux/thunk/auth.thunk";
import { toast } from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import useResponsive from "hooks/useResponsive";
import Astaanta from "assets/logo.png";
import Image from "components/Image";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  justifyContent: "center",
  hieght: "100%",
  marginTop: "8%",
  padding: theme?.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function TwoStepAuthentication() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const smUp = useResponsive("up", "sm");

  const user = state.user;
  const values = {
    emailOrUsername: state.auth.username,
    password: state.auth.password,
  };

  const sendCodeAgain = async () => {
    try {
      setSubmitting(true);
      const res = await dispatch(loginUser(values));
      setSubmitting(false);
      if (res.type === "auth/login-user/fulfilled") {
        toast.success("Verification code resent successfully.");
      } else if (res.type === "auth/login-user/rejected") {
        toast.error(
          `${
            res
              ? res.payload.response.data.error
              : "Failed to connect due to network or server error"
          }`
        );
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Page title="Verify User | Archice Panel" sx={{ hieght: 1 }}>
      <RootStyle>
        {/* <LogoOnlyLayout /> */}

        <Container>
          <Box sx={{ maxWidth: 480, mx: "auto" }}>
            <>
              <img
                src={Astaanta}
                width={120}
                height={120}
                style={{ margin: "auto", marginBottom: "4%" }}
              />
              <Typography variant="h3" paragraph>
                Two Step Authentication ?
              </Typography>
              <Typography sx={{ color: "text.secondary", mb: 5 }}>
                Please enter the OTP (one time password) that has been sent to
                your registered email address.
              </Typography>

              <TwoStepAuthenticationForm user={user} />
              <LoadingButton
                fullWidth
                size="large"
                loading={isSubmitting}
                onClick={sendCodeAgain}
                sx={{ mt: 1 }}
              >
                Didn't get the OTP code ?
              </LoadingButton>
            </>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
