import { createSlice } from "@reduxjs/toolkit";
import { VerifyUser, loginUser } from "../thunk/auth.thunk";

// Function to load data from localStorage
const loadFromLocalStorage = (key) => {
  try {
    const serializedData = localStorage.getItem(key);
    return serializedData ? JSON.parse(serializedData) : null;
  } catch (err) {
    console.error(`Could not load ${key} from localStorage`, err);
    return null;
  }
};

// Function to save data to localStorage
const saveToLocalStorage = (key, data) => {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
  } catch (err) {
    console.error(`Could not save ${key} to localStorage`, err);
  }
};

// Initial state
const initialState = {
  user: loadFromLocalStorage("user") || {},
  isAuthenticated: !!loadFromLocalStorage("accessToken"),
  loading: false,
  error: null,
  accessToken: loadFromLocalStorage("accessToken"),
  tfaEnabled: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.user = {};
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.accessToken = null;
      state.tfaEnabled = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
    },
    setTfaEnabled: (state, action) => {
      state.tfaEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload.user;
        state.isAuthenticated = true;
        state.accessToken = payload.token;
        saveToLocalStorage("accessToken", payload.token);
        saveToLocalStorage("user", payload.user);
      })
      .addCase(loginUser.rejected, (state, { error }) => {
        state.error = error.message;
        state.isAuthenticated = false;
        state.loading = false;
        state.user = initialState.user;
        state.accessToken = null;
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
      });
  },
});

export const { reset, setTfaEnabled } = authSlice.actions;

export default authSlice.reducer;
