import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  chats: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    getChatSucess: (state, { payload }) => {
      state.chats = payload;
      state.loading = false;
    },
    startLoadingChat: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllMessages = ({ accessToken, page }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingChat());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-chat`,
        { headers }
      );
      dispatch(slice.actions.getChatSucess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};