import React, { Component } from 'react';
import ErrorPage from './ErrorPage'; // This will be your error page component

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage error={this.state.error} />;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
