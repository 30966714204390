import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  deposits: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "deposit",
  initialState,
  reducers: {
    getDepositsSuccess: (state, action) => {
      state.deposits = action.payload;
      state.loading = false;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllDeposits = ({ accessToken }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-deposits`,
        { headers }
      );
      dispatch(slice.actions.getDepositsSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};
