import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  receipts: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    getReceiptSuccess: (state, { payload }) => {
      state.receipts = payload;
      state.loading = false;
    },
    startLoadingReceipt: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllReceipts = ({ accessToken, page }) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingReceipt());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-receipts`,
        { headers }
      );
      dispatch(slice.actions.getReceiptSuccess(res.data));
       
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};
