import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducer from "../slices/users.slice";
import authReducer from "../slices/auth.slice";
import roleReducer from "../slices/role.slice";
import logsReducer from "redux/slices/logs.slice";
import trashReducer from "redux/slices/trash.slice";
import systemInfoReducer from "redux/slices/systemInfo.slice";
import chatReducer from "redux/slices/chat.slice";
import messageReducer from "redux/slices/messages_slice";
import accountReducer from "redux/slices/accounts.slice";
import expenseReducer from "redux/slices/expense.slice";
import productReducer from "redux/slices/products_slice";
import branchReducer from "redux/slices/branch_slice";
import categoryReducer from "redux/slices/category_slice";
import smsReducer from "redux/slices/sms.slice";
import supplierReducer from "redux/slices/supplier_slice";
import purchaseReducer from "redux/slices/purchases.slice";
import depositReducer from "redux/slices/deposit.slice";
import withdrawalReducer from "redux/slices/withdrawal.slice";
import eventReducer from "redux/slices/eventSlice";
import outMemberReducer from "redux/slices/outMember_slice";
import customerReducer from "redux/slices/customers_slice";
import notificationReducer from "redux/slices/notifications.slice";
import EmployeeReducer from "redux/slices/employeeSlice";
import departmentReducer from "redux/slices/departmentSlice";
import payoutReducer from "redux/slices/payoutSlice";
import leaveReducer from "redux/slices/leaveSlice";
import fileReducer from "redux/slices/file.slice";
import archiveReducer from "redux/slices/archive.slice";
import downloadReducer from "redux/slices/download.slice";
import signReducer from "redux/slices/signature.slice";
import activeUserSlice from "redux/slices/activeUserSlice";
import chatsSlice from "redux/slices/chatsSlice";
import profileSlice from "redux/slices/profileSlice";
import searchSlice from "redux/slices/searchSlice";
import contractSlice from "redux/slices/contract_slice";
import mdaReducer from "redux/slices/mdaSlice";
import rbookReducer from "redux/slices/rbooks_slice";
import receiptReducer from "redux/slices/receipts_slice";
import fmisReducer from "redux/slices/fmiss_slice";
import deviceReducer from "redux/slices/deviceSlice";
import mdaAgentReducer from "redux/slices/mdaAgentSlice";
import auditStageReducer from "redux/slices/auditStages_slice";
import backupReducer from "redux/slices/backupSlice";
import receiptIssueReducer from "redux/slices/receiptIssue_slice";

import localforage from "localforage";

const persistConfig = {
  keyPrefix: "redux-",
  key: "root",
  storage: localforage,

  whitelist: [
    "auth",
    "user",
  ],
};

const rootReducer = combineReducers({
  // Add your reducers here
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  logs: logsReducer,
  trash: trashReducer,
  systemInfo: systemInfoReducer,
  chat: chatReducer,
  message: messageReducer,
  account: accountReducer,
  expense: expenseReducer,
  product: productReducer,
  branch: branchReducer,
  category: categoryReducer,
  sms: smsReducer,
  supplier: supplierReducer,
  purchase: purchaseReducer,
  deposit: depositReducer,
  withdrawal: withdrawalReducer,
  event: eventReducer,
  outMember: outMemberReducer,
  customer: customerReducer,
  notification: notificationReducer,
  employee: EmployeeReducer,
  department: departmentReducer,
  payout: payoutReducer,
  leave: leaveReducer,
  file: fileReducer,
  archive: archiveReducer,
  download: downloadReducer,
  signature: signReducer,
  activeUser: activeUserSlice,
  profile: profileSlice,
  search: searchSlice,
  chats: chatsSlice,
  contract: contractSlice,
  mda: mdaReducer,
  rbook: rbookReducer,
  receipt: receiptReducer,
  fmis: fmisReducer,
  device: deviceReducer,
  mdaAgent: mdaAgentReducer,
  auditStage: auditStageReducer,
  backup: backupReducer,
  receiptIssue: receiptIssueReducer,

});

export { persistConfig, rootReducer };
