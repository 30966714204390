import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  signatures: {
    data: [],
    currentPage: 0,
    totalPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "signature",
  initialState,
  reducers: {
    getsignatureSuccess: (state, action) => {
      state.signatures = action.payload;
      state.loading = false;
    },
    startLoadingsignature: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllSignatures = ({ accessToken, page }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingsignature());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-signatures`,
        { headers }
      );
      dispatch(slice.actions.getsignatureSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};
