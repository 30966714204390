import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// hooks

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { auth } = useSelector((state) => state);
  const { isAuthenticated } = auth;

  if (isAuthenticated) {
    return <Navigate to="/dashboard/app" />;
  }

  return <>{children}</>;
}
