import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  roles: {
    data: [],
    currentPage: 0,
    totalPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getRoleSuccess: (state, action) => {
      state.roles = action.payload;
      state.loading = false;
    },
    startLoadingRole: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getRoles = ({ accessToken, page }) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingRole());
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get-all-roles`, { headers });
      dispatch(slice.actions.getRoleSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};
