import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar, Icon } from "@mui/material";
// hooks
import useResponsive from "hooks/useResponsive";
// components
import Scrollbar from "components/Scrollbar";
// import NavSection from "components/NavSection";
import NavConfig from "./NavConfig";
import NavSection2 from "components/NavSection2";
import { useDispatch, useSelector } from "react-redux";
import defaultLogo from "assets/logo.png";
import useAuth from "hooks/useAuth";
import { getSystemInfo } from "redux/slices/systemInfo.slice";
import { getUsers } from "redux/slices/users.slice";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 300;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { systemInfo } = useSelector((state) => state.systemInfo);
  const { user, accessToken } = useAuth();
  const isDesktop = useResponsive("up", "lg");
  const year = new Date().getFullYear();
  const readNav = NavConfig();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    dispatch(getSystemInfo({ accessToken }));
    dispatch(getUsers({ accessToken }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accessToken, pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ display: "inline-flex", m: "4% 0 10px 25px" }}>
        <Box
          sx={{ width: 140, height: 140, mt: "0", mb: "0" }}
          component="img"
          src={
            // `${process.env.REACT_APP_BACKEND_API}/uploads/systemInfo/${systemInfo?.data?.[0]?.logo}` ||
            defaultLogo
          }
        ></Box>
      </Box>
      {/* <Box sx={{ px: 3, display: "inline-flex" }} /> */}

      <NavSection2 navConfig={readNav} />

      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          p: 2.5,
          display: "block",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.neutral",
        }}
      >
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          © 2023 - {year} POWERED BY{" "}
          <a
            style={{ textDecoration: "none", color: "rgb(200,200,200)" }}
            href="https://oag.gov.so"
          >
            <strong>ICT DEPT</strong>
          </a>
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary",}}>
          CONNECTING AUDITS, ENHANCING ACCOUNTABILITY
        </Typography>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "solid",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
