import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { reset } from "redux/slices/auth.slice";
import LoadingScreen from "components/LoadingScreen";
import DashboardLayout from "layouts/dashboard";
import LogoOnlyLayout from "layouts/LogoOnlyLayout";
//
import GuestGuard from "guards/GuestGuard";
import PageBasedGuard from "guards/PageBasedGuard";
import AuthGuard from "guards/AuthGuard";
import NotFound from "components/Page404";
import ComingSoon from "components/ComingSoon";
import { useState } from "react";
import useAuth from "hooks/useAuth";
import WelcomeImg from "../assets/welcome.svg";
import TwoStepAuthentication from "pages/@auth/TwoStepAuthentication";
import UnauthorizedAccess from "pages/@dash/PlaceHolder";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};

function LogOutDialog() {
  const [openDialog, setOpenDialog] = useState(true);
  const [onClose, setOnClose] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    await dispatch(reset());
    setOnClose(true);
    navigate("/auth/login", { replace: true });
  };
  const handleCancelDialog = () => {
    setOnClose(true);
    setOpenDialog(false);
    navigate(-1);
  };

  return (
    <Dialog open={openDialog} onClose={onClose}>
      <DialogTitle>Are you sure you want to logout?</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          You will be logged out of your account.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleCancelDialog()}>Cancel</Button>
        <Button onClick={handleLogout} color="error">
          Log Out
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function Router() {
  const { user, accessToken } = useAuth();
  const { users } = useSelector((state) => state.user);
  const loggedUser = user;

  return useRoutes([
    {
      path: "/auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "logout",
          element: <LogOutDialog />,
        },
        {
          path: "forget-password",
          element: (
            <GuestGuard>
              <ForgetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password/:resetToken",
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "verification",
          element: <TwoStepAuthentication />,
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),

      children: [
        { path: "unauthorized", element: <UnauthorizedAccess /> },

        { path: "account", element: <Account /> },
        // { path: "profile", element: <Profile /> },

        //assets
        loggedUser?.role?.permissions?.includes("canViewSuppliers")
          ? {
            path: "assets/suppliers",
            element: <SupplierList />,
          }
          : {
            path: "assets/suppliers",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateSuppliers")
          ? {
            path: "assets/suppliers/new-supplier",
            element: <SupplierForm />,
          }
          : {
            path: "assets/suppliers",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditSuppliers")
          ? {
            path: "assets/suppliers/edit-supplier/:_id",
            element: <SupplierForm />,
          }
          : {
            path: "assets/suppliers",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canViewPurchases")
          ? {
            path: "assets/purchases",
            element: <PurchaseList />,
          }
          : {
            path: "assets/purchases",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreatePurchases")
          ? {
            path: "assets/purchases/new-purchase",
            element: <PurchaseForm />,
          }
          : {
            path: "assets/purchases",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditPurchases")
          ? {
            path: "assets/purchases/edit-purchase/:_id",
            element: <PurchaseForm />,
          }
          : {
            path: "assets/purchases",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canViewProducts")
          ? {
            path: "assets/products",
            element: <ProductList />,
          }
          : {
            path: "assets/products",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateProducts")
          ? {
            path: "assets/products/new-product",
            element: <ProductForm />,
          }
          : {
            path: "assets/products",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditProducts")
          ? {
            path: "assets/products/edit-products/:_id",
            element: <ProductForm />,
          }
          : {
            path: "assets/products",
            element: <UnauthorizedAccess />,
          },

        // receipts
        loggedUser?.role?.permissions?.includes("canViewReceipts")
          ? {
            path: "receipts/rbooks",
            element: <RbookList />,
          }
          : {
            path: "receipts/rbooks",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateRbooks")
          ? {
            path: "receipts/rbooks/new-rbook",
            element: <RbookForm />,
          }
          : {
            path: "receipts/rbooks",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditRbooks")
          ? {
            path: "receipts/rbooks/edit-rbook/:_id",
            element: <RbookForm />,
          }
          : {
            path: "receipts/rbooks",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canViewReceipts")
          ? {
            path: "receipts/receipts",
            element: <ReceiptList />,
          }
          : {
            path: "receipts/receipts",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateReceipts")
          ? {
            path: "receipts/receipts/new-receipt",
            element: <ReceiptForm />,
          }
          : {
            path: "receipts/receipts",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditReceipts")
          ? {
            path: "receipts/receipts/edit-receipt/:_id",
            element: <ReceiptForm />,
          }
          : {
            path: "receipts/receipts",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canViewFmis")
          ? {
            path: "receipts/fmis",
            element: <FmisList />,
          }
          : {
            path: "receipts/fmis",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateFmis")
          ? {
            path: "receipts/fmis/new-fmis",
            element: <FmisForm />,
          }
          : {
            path: "receipts/fmis",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditFmis")
          ? {
            path: "receipts/fmis/edit-fmis/:_id",
            element: <FmisForm />,
          }
          : {
            path: "receipts/fmis",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewMdaAgents")
          ? {
            path: "receipts/mdaAgent",
            element: <MdaAgentList />,
          }
          : {
            path: "receipts/mdaAgent",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateMdaAgents")
          ? {
            path: "receipts/mdaAgent/new-mdaAgent",
            element: <MdaAgentForm />,
          }
          : {
            path: "receipts/mdaAgent",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditMdaAgents")
          ? {
            path: "receipts/mdaAgent/edit-mdaAgent/:_id",
            element: <MdaAgentForm />,
          }
          : {
            path: "receipts/mdaAgent",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canViewReceiptIssues")
          ? {
            path: "receipts/receipt_issues/list",
            element: <ReceiptIssue />,
          }
          : {
            path: "receipts/receipt_issues/list",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateReceiptIssues")
          ? {
            path: "receipts/receipt_issues/list/new-receiptIssue",
            element: <ReceiptIssueForm />,
          }
          : {
            path: "receipts/receipt_issues/list/new-receiptIssue",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditReceiptIssues")
          ? {
            path: "receipts/receipt_issues/list/edit-receiptIssue/:_id",
            element: <ReceiptIssueForm />,
          }
          : {
            path: "receipts/receipt_issues/list/edit-receiptIssue",
            element: <UnauthorizedAccess />,
          },

        // contracts
        loggedUser?.role?.permissions?.includes("canViewContract")
          ? {
            path: "contracts/list",
            element: <ContractList />,
          }
          : {
            path: "contracts/list",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewContract")
          ? {
            path: "contracts/rejected",
            element: <RejectedList />,
          }
          : {
            path: "contracts/rejected",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewContract")
          ? {
            path: "contracts/archived",
            element: <ArchivedList />,
          }
          : {
            path: "contracts/archived",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateContract")
          ? {
            path: "contracts/list/new-contract",
            element: <ContractForm />,
          }
          : {
            path: "contracts/list",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditContract") ||
          loggedUser?.role?.permissions?.includes("canReviewContract")
          ? {
            path: "contracts/list/edit-contract/:_id",
            element: <ContractForm />,
          }
          : {
            path: "contracts/contracts",
            element: <UnauthorizedAccess />,
          },

        // audits
        loggedUser?.role?.permissions?.includes("canViewAuditAnalytics")
          ? {
            path: "audits/audit-analytics",
            element: <AuditAnalytics />,
          }
          : {
            path: "audits/audit-analytics",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewFinancialAudits")
          ? {
            path: "audits/financial-audits",
            element: <AuditStageList />,
          }
          : {
            path: "audits/financial-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewComplianceAudits")
          ? {
            path: "audits/compliance-audits",
            element: <ComplianceStageList />,
          }
          : {
            path: "audits/compliance-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewForensicAudits")
          ? {
            path: "audits/forensic-audits",
            element: <ForensicStageList />,
          }
          : {
            path: "audits/forensic-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewPerformanceAudits")
          ? {
            path: "audits/performance-audits",
            element: <PerformanceStageList />,
          }
          : {
            path: "audits/performance-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewInformationAudits")
          ? {
            path: "audits/isa",
            element: <ISAList />,
          }
          : {
            path: "audits/isa",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewSpecialAudits")
          ? {
            path: "audits/special-audits",
            element: <SpecialStageList />,
          }
          : {
            path: "audits/special-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
            path: "audits/financial-audits/new-auditStage",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/financial-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
            path: "audits/financial-audits/edit-auditStage/:_id",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/financial-audits",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
            path: "audits/compliance-audits/new-auditStage",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/compliance-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
            path: "audits/compliance-audits/edit-auditStage/:_id",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/compliance-audits",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
            path: "audits/performance-audits/new-auditStage",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/performance-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
            path: "audits/performance-audits/edit-auditStage/:_id",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/performance-audits",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
            path: "audits/isa/new-auditStage",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/isa",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
            path: "audits/isa/edit-auditStage/:_id",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/isa",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
            path: "audits/special-audits/new-auditStage",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/special-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
            path: "audits/special-audits/edit-auditStage/:_id",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/special-audits",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canCreateAuditStages")
          ? {
            path: "audits/forensic-audits/new-auditStage",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/forensic-audits",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditAuditStages")
          ? {
            path: "audits/forensic-audits/edit-auditStage/:_id",
            element: <AuditStageForm />,
          }
          : {
            path: "audits/forensic-audits",
            element: <UnauthorizedAccess />,
          },

        // calendar
        loggedUser?.role?.permissions?.includes("canViewCalendar")
          ? {
            path: "calendar/calendar",
            element: <Calendar />,
          }
          : {
            path: "calendar/calendar",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewCalendar")
          ? {
            path: "calendar/calendar2",
            element: <Calendar2 />,
          }
          : {
            path: "calendar/calendar",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewOutMembers")
          ? {
            path: "calendar/outMembers",
            element: <OutMemberList />,
          }
          : {
            path: "calendar/outMembers",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateOutMembers")
          ? {
            path: "calendar/outMembers/new-outMember",
            element: <OutMemberForm />,
          }
          : {
            path: "calendar/outMembers",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditOutMembers")
          ? {
            path: "calendar/outMembers/edit-outMember/:_id",
            element: <OutMemberForm />,
          }
          : {
            path: "calendar/outMembers",
            element: <UnauthorizedAccess />,
          },

        // projects
        loggedUser?.role?.permissions?.includes("canViewProjects")
          ? {
            path: "projects",
            element: (
              <ProjectLayout>
                <div className="flex flex-col items-center w-full pt-10">
                  <img src={WelcomeImg} className="w-5/12" alt="" />
                  <h1 className="text-lg text-gray-600">
                    Select or create new project
                  </h1>
                </div>
              </ProjectLayout>
            ),
          }
          : {
            path: "projects",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewProjects")
          ? {
            path: "projects/:projectId",
            element: (
              <ProjectLayout>
                <ProjectManager />
              </ProjectLayout>
            ),
          }
          : {
            path: "projects",
            element: <UnauthorizedAccess />,
          },

        //crm
        loggedUser?.role?.permissions?.includes("canViewCustomers")
          ? {
            path: "crm/customers",
            element: <CustomerList />,
          }
          : {
            path: "crm/customers",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateCustomers")
          ? {
            path: "crm/customers/new-customer",
            element: <CustomerForm />,
          }
          : {
            path: "crm/customers",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditCustomers")
          ? {
            path: "crm/customers/edit-customer/:_id",
            element: <CustomerForm />,
          }
          : {
            path: "crm/customers",
            element: <UnauthorizedAccess />,
          },

        //chats
        loggedUser?.role?.permissions?.includes("canViewChats")
          ? {
            path: "chats",
            element: <Chats />,
          }
          : {
            path: "chats",
            element: <UnauthorizedAccess />,
          },

        // accounting
        loggedUser?.role?.permissions?.includes("canViewExpenses")
          ? {
            path: "accounting/expenses",
            element: <ExpenseList />,
          }
          : {
            path: "accounting/expenses",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateExpenses")
          ? {
            path: "accounting/expenses/new-expense",
            element: (
              // <PageBasedGuard>
              <ExpenseForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/expenses",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditExpenses")
          ? {
            path: "accounting/expenses/edit-expense/:_id",
            element: (
              // <PageBasedGuard>
              <ExpenseForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/expenses",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewAccounts")
          ? {
            path: "accounting/accounts",
            element: (
              // <PageBasedGuard>
              <AccountsList />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/accounts",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateAccounts")
          ? {
            path: "accounting/accounts/new-account",
            element: (
              // <PageBasedGuard>
              <AccountsForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/accounts",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditAccounts")
          ? {
            path: "accounting/accounts/edit-account/:_id",
            element: (
              // <PageBasedGuard>
              <AccountsForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/accounts",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewDeposit")
          ? {
            path: "accounting/deposit",
            element: (
              // <PageBasedGuard>
              <DepositList />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/deposit",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateDeposit")
          ? {
            path: "accounting/deposit/new-deposit",
            element: (
              // <PageBasedGuard>
              <DepositForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/deposit",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditDeposit")
          ? {
            path: "accounting/deposit/edit-deposit/:_id",
            element: (
              // <PageBasedGuard>
              <DepositForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/deposit",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewWithdrawal")
          ? {
            path: "accounting/withdrawal",
            element: (
              // <PageBasedGuard>
              <WithdrawalList />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/withdrawal",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateWithdrawal")
          ? {
            path: "accounting/withdrawal/new-withdrawal",
            element: (
              // <PageBasedGuard>
              <WithdrawalForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/withdrawal",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditWithdrawal")
          ? {
            path: "accounting/withdrawal/edit-withdrawal/:_id",
            element: (
              // <PageBasedGuard>
              <WithdrawalForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/withdrawal",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewAccountPayable")
          ? {
            path: "accounting/accountPayable",
            element: (
              // <PageBasedGuard>
              <AccountPayableList />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/accountsPayable",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canViewBalanceSheet")
          ? {
            path: "accounting/balancesheet",
            element: (
              // <PageBasedGuard>
              <BalanceSheetList />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "accounting/balancesheet",
            element: <UnauthorizedAccess />,
          },

        //hr
        loggedUser?.role?.permissions?.includes("canViewDepartments")
          ? {
            path: "hr/departments",
            element: <DeptList />,
          }
          : {
            path: "hr/departments",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateDepartments")
          ? {
            path: "hr/departments/new-department",
            element: <DeptForm />,
          }
          : {
            path: "hr/departments",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditDepartments")
          ? {
            path: "hr/departments/edit-department/:_id",
            element: <DeptForm />,
          }
          : {
            path: "hr/departments",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewEmployees")
          ? {
            path: "hr/employees",
            element: <EmpList />,
          }
          : {
            path: "hr/employees",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateEmployees")
          ? {
            path: "hr/employees/new-employee",
            element: <EmpForm />,
          }
          : {
            path: "hr/employees",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditEmployees")
          ? {
            path: "hr/employees/edit-employee/:_id",
            element: <EmpForm />,
          }
          : {
            path: "hr/employees",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewPayouts")
          ? {
            path: "hr/payouts",
            element: <PayoutList />,
          }
          : {
            path: "hr/payouts",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreatePayouts")
          ? {
            path: "hr/payouts/new-payout",
            element: <PayoutForm />,
          }
          : {
            path: "hr/payouts",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditPayouts")
          ? {
            path: "hr/payouts/edit-payout/:_id",
            element: <PayoutForm />,
          }
          : {
            path: "hr/payouts",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewLeaves")
          ? {
            path: "hr/leaves",
            element: <LeaveList />,
          }
          : {
            path: "hr/leaves",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateLeaves")
          ? {
            path: "hr/leaves/new-leave",
            element: <LeaveForm />,
          }
          : {
            path: "hr/leaves",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditLeaves")
          ? {
            path: "hr/leaves/edit-leave/:_id",
            element: <LeaveForm />,
          }
          : {
            path: "hr/leaves",
            element: <UnauthorizedAccess />,
          },
        {
          path: "hr/attendance",
          element: <ComingSoon />,
        },

        // archive
        loggedUser?.role?.permissions?.includes("canViewFileManager")
          ? {
            path: "archive/fileManager",
            element: <FileManager3 />,
          }
          : {
            path: "archive/fileManager",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewUploads")
          ? {
            path: "archive/files/uploads",
            element: <FileList />,
          }
          : {
            path: "archive/uploads",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewDownloads")
          ? {
            path: "archive/files/downloads",
            element: <DownloadList />,
          }
          : {
            path: "archive/downloads",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewUploads")
          ? {
            path: "archive/files/shared",
            element: <SharedFiles />,
          }
          : {
            path: "archive/shared",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewArchive")
          ? {
            path: "archive/archives",
            element: <ArchiveList />,
          }
          : {
            path: "archive/archives",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditArchive")
          ? {
            path: "archive/archives/edit-archive/:_id",
            element: <ArchiveForm />,
          }
          : {
            path: "archive/archives",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditArchive")
          ? {
            path: "archive/archives/new-archive",
            element: <ArchiveForm />,
          }
          : {
            path: "archive/archives",
            element: <UnauthorizedAccess />,
          },

        // settings
        loggedUser?.role?.permissions?.includes("canViewMda")
          ? {
            path: "settings/mda",
            element: <MdaList />,
          }
          : {
            path: "settings/mda",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateMda")
          ? {
            path: "settings/mda/new-mda",
            element: <MdaForm />,
          }
          : {
            path: "settings/mda",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditMda")
          ? {
            path: "settings/mda/edit-mda/:_id",
            element: <MdaForm />,
          }
          : {
            path: "settings/mda",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewBranches")
          ? {
            path: "settings/branches",
            element: <BranchList />,
          }
          : {
            path: "settings/branches",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateBranches")
          ? {
            path: "settings/branches/new-branch",
            element: <BranchForm />,
          }
          : {
            path: "settings/branches",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditBranches")
          ? {
            path: "settings/branches/edit-branch/:_id",
            element: <BranchForm />,
          }
          : {
            path: "settings/branches",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewNotifications")
          ? {
            path: "settings/notifications",
            element: <NotificationList />,
          }
          : {
            path: "settings/notifications",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateNotifications")
          ? {
            path: "settings/notifications/new-notification",
            element: <NotificationForm />,
          }
          : {
            path: "settings/notifications",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditNotifications")
          ? {
            path: "settings/notifications/edit-notifications/:_id",
            element: <NotificationForm />,
          }
          : {
            path: "settings/notifications",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewCategories")
          ? {
            path: "assets/categories",
            element: <CategoryList />,
          }
          : {
            path: "assets/categories",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateCategories")
          ? {
            path: "assets/categories/new-category",
            element: <CategoryForm />,
          }
          : {
            path: "assets/categories",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditCategories")
          ? {
            path: "assets/categories/edit-category/:_id",
            element: <CategoryForm />,
          }
          : {
            path: "assets/categories",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canViewDevices")
          ? {
            path: "settings/devices",
            element: <DeviceList />,
          }
          : {
            path: "settings/devices",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateDevices")
          ? {
            path: "settings/devices/new-device",
            element: <DeviceForm />,
          }
          : {
            path: "settings/devices",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditDevices")
          ? {
            path: "settings/devices/edit-device/:_id",
            element: <DeviceForm />,
          }
          : {
            path: "settings/devices",
            element: <UnauthorizedAccess />,
          },

        loggedUser?.role?.permissions?.includes("canViewUsers")
          ? {
            path: "settings/users",
            element: <UserList />,
          }
          : {
            path: "settings/users",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateUsers")
          ? {
            path: "settings/users/new-user",
            element: <UserForm />,
          }
          : {
            path: "settings/users",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditUsers")
          ? {
            path: "settings/users/edit-user/:_id",
            element: <UserForm />,
          }
          : {
            path: "settings/users",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewRoles")
          ? {
            path: "settings/roles",
            element: <Roles />,
          }
          : {
            path: "settings/roles",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canCreateRoles")
          ? {
            path: "settings/roles/new-role",
            element: <RoleForm />,
          }
          : {
            path: "settings/roles",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditRoles")
          ? {
            path: "settings/roles/edit-role/:_id",
            element: <RoleForm />,
          }
          : {
            path: "settings/roles",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditSMS")
          ? {
            path: "settings/sms",
            element: (
              // <PageBasedGuard>
              <SmsForm />
              //</PageBasedGuard>
            ),
          }
          : {
            path: "settings/sms",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canEditSystemInfo")
          ? {
            path: "settings/systemInfo",
            element: <SystemInfo />,
          }
          : {
            path: "settings/systemInfo",
            element: <UnauthorizedAccess />,
          },
        loggedUser?.role?.permissions?.includes("canViewLogs")
          ? {
            path: "settings/logs",
            element: <LogsList />,
          }
          : {
            path: "settings/logs",
            element: <UnauthorizedAccess />,
          },

        // backup
        loggedUser?.role?.permissions?.includes("canViewBackups")
          ? {
            path: "settings/backups",
            element: <BackupList />,
          }
          : {
            path: "settings/backups",
            element: <UnauthorizedAccess />,
          },
        {
          path: "trash/UserTrash",
          element: <UserTrash />,
        },
        {
          path: "trash/FileTrash",
          element: <FileTrash />,
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        ({ element: <Navigate to="/dashboard/app" replace /> },
          loggedUser?.role?.permissions?.includes("canViewDashboard")
            ? { path: "app", element: <DashboardApp /> }
            : {
              path: "/dashboard/app",
              element: <UnauthorizedAccess />,
            }),
      ],
    },

    {
      path: "/",
      // element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard/app" /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "coming-soon", element: <ComingSoon /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
  
}

const DashboardApp = Loadable(lazy(() => lazyRetry(() => import("pages/@dash/DashboardApp"))));

// users
const UserList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/users/List/UserList"))));
const UserForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/users/Form/UserForm"))));

// devices
const DeviceList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/devices/List/DeviceList"))));
const DeviceForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/devices/Form/DeviceForm"))));

// receipts
const RbookList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/receipt_books/List/RbookList"))));
const RbookForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/receipt_books/Form/RbookForm"))));
const ReceiptList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/receipts/List/ReceiptList"))));
const ReceiptForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/receipts/Form/ReceiptForm"))));
const ReceiptIssue = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/receipt_issues/List/ReceiptIssueList"))));
const ReceiptIssueForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/receipt_issues/Form/ReceiptIssueForm"))));

const AuditAnalytics = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/audit_stages/List/AuditAnalytics"))));
const AuditStageList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/audit_stages/List/AuditStageList"))));
const ForensicStageList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/audit_stages/List/ForensicAuditList"))));
const ComplianceStageList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/audit_stages/List/ComplianceAuditList"))));
const PerformanceStageList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/audit_stages/List/PerformanceAuditList"))));
const ISAList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/audit_stages/List/ISAList"))));
const SpecialStageList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/audit_stages/List/SpecialAuditList"))));

const AuditStageForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/audit_stages/Form/AuditStageForm"))));

const MdaAgentList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/mda_agents/List/AgentList"))));
const MdaAgentForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/mda_agents/Form/AgentForm"))));
const FmisList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/fmis/List/FmistList"))));
const FmisForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/fmis/Form/FmisForm"))));

const BackupList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/backups/List/BackupList"))));

// calendar
const Calendar = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/calendar/Calendar"))));
const Calendar2 = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/calendar/Calendar2"))));
const OutMemberList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/outMembers/List/OutMemberList"))));
const OutMemberForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/outMembers/Form/OutMemberForm"))));

// projects
const ProjectManager = Loadable(lazy(() => lazyRetry(() => import("components/projects/Task"))));
const ProjectLayout = Loadable(lazy(() => lazyRetry(() => import("components/projects/AppLayout"))));

// archive
const FileManager3 = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/archive/fileManager/NewFileManager2"))));
const ArchiveList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/archive/archives/ArchiveList"))));
const ArchiveForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/archive/archives/ArchiveForm"))));
const FileList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/archive/files/FilesList"))));
const DownloadList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/archive/downloads/DownloadList"))));
const SharedFiles = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/archive/files/SharedFiles"))));

// suppliers
const SupplierList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/suppliers/List/SupplierList"))));
const SupplierForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/suppliers/Form/SupplierForm"))));

// purchases
const PurchaseList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/purchases/List/PurchasesList"))));
const PurchaseForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/purchases/Form/PurchaseForm"))));

// products
const ProductList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/products/List/ProductList"))));
const ProductForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/products/Form/ProductForm"))));

// mda
const MdaList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/mda/List/MdaList"))));
const MdaForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/mda/Form/MdaForm"))));

// branches
const BranchList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/branches/List/BranchList"))));
const BranchForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/branches/Form/BranchForm"))));

// contracts
const ContractList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/contracts/List/ContractList"))));
const ContractForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/contracts/Form/ContractForm"))));
const ArchivedList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/contracts/List/ArchivedContracts"))));
const RejectedList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/contracts/List/RejectedContracts"))));

// notifications
const NotificationList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/notifications/List/NotificationsList"))));
const NotificationForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/notifications/Form/NotificationForm"))));

// mail and sms
const SmsForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/sms/Form/SmsForm"))));

// categories
const CategoryList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/categories/List/CategoryList"))));
const CategoryForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/categories/Form/CategoryForm"))));

// customers
const CustomerList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/customers/List/CustomerList"))));
const CustomerForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/customers/Form/CustomerForm"))));

// accounting routes
const AccountsList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/accounts/List/AccountsList"))));
const AccountsForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/accounts/Form/AccountsForm"))));

// deposit and withdrawal routes
const DepositList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/deposit/List/DepositList"))));
const DepositForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/deposit/Form/DepositForm"))));
const WithdrawalList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/withdrawal/List/WithdrawalList"))));
const WithdrawalForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/withdrawal/Form/WithdrawalForm"))));

// accounting
const ExpenseList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/expenses/List/ExpenseList"))));
const ExpenseForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/expenses/Form/ExpenseForm"))));

const AccountPayableList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/accountPayable/AccountPayableList"))));

const BalanceSheetList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/accounting/balanceSheet/List/BalanceSheetList"))));

// department routes
const DeptList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/hr/departments/List/DepartmentList"))));
const DeptForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/hr/departments/Form/DepartmentForm"))));

// employee routes
const EmpList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/hr/employees/List/EmployeeLists"))));
const EmpForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/hr/employees/Form/EmployeeForm"))));

// payout routes
const PayoutList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/hr/payouts/List/PayoutList"))));
const PayoutForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/hr/payouts/Form/PayoutForm"))));

// leave routes
const LeaveList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/hr/leaves/List/LeaveList"))));
const LeaveForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/hr/leaves/Form/LeaveForm"))));

// chats
const ChatList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/chatting/List/chatList"))));
const Chats = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/chatting/Home"))));

// login
const Login = Loadable(lazy(() => lazyRetry(() => import("pages/@auth/Login"))));
// const Register = Loadable(lazy(() => lazyRetry(() => import("views/@auth/Register")));
const Account = Loadable(lazy(() => lazyRetry(() => import("admin/Account"))));
const ForgetPassword = Loadable(lazy(() => lazyRetry(() => import("pages/@auth/ForgetPassword"))));
const ResetPassword = Loadable(lazy(() => lazyRetry(() => import("pages/@auth/ResetPassword"))));

// settings
// roles routes
const Roles = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/roles/List/RoleList"))));
const RoleForm = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/roles/Form/RoleForm"))));

const LogsList = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/Logs/LogsList"))));
const UserTrash = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/Trash/UserTrash/UserTrash"))));
const FileTrash = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/Trash/FileTrash/FileTrash"))));
const SystemInfo = Loadable(lazy(() => lazyRetry(() => import("pages/_directory/systemInfo/SystemInfo"))));
