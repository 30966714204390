import axios from "axios";
import { toast } from "react-toastify";
const API = (token) =>
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    headers: { Authorization: token },
  });
let url = process.env.REACT_APP_BACKEND_API;
export const loginUser = async (body) => {
  try {
    return await axios.post(`${url}/auth/login`, body);
  } catch (error) {
    toast.error("error in loginuser api");
  }
};
export const googleAuth = async (body) => {
  try {
    return await axios.post(`${url}/api/google`, body);
  } catch (error) {
    toast.error(error);
  }
};
export const registerUser = async (body) => {
  try {
    return await axios.post(`${url}/auth/register`, body);
  } catch (error) {
    toast.error("error in register api");
  }
};
export const validUser = async (token) => {
  try {
    const { data } = await API(token).get(`/auth/valid`, {
      headers: { Authorization: token },
    });
    return data;
  } catch (error) {
    toast.error("error in valid user api");
  }
};
export const searchUsers = async (username, users) => {
  try {
    return await {
      data: users?.data?.filter((user) => user.username.includes(username)),
    };
  } catch (error) {
    toast.error("error in search users api");
  }
};
export const updateUser = async (id, body) => {
  try {
    const token = localStorage.getItem("userToken");

    const { data } = await API(token).patch(`/api/users/update/${id}`, body);
    return data;
  } catch (error) {
    toast.error("Something Went Wrong.try Again!");
  }
};
export const checkValid = async () => {
  const data = await validUser();
  if (!data?.user) {
    window.location.href = "/login";
  } else {
    window.location.href = "/chats";
  }
};
