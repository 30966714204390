import { useState } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Form, FormikProvider, useFormik } from "formik";
import { VerifyUser } from "redux/thunk/auth.thunk";
// material
import { TextField, Alert, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

TwoStepAuthenticationForm.propTypes = {
  user: PropTypes.object,
};

export default function TwoStepAuthenticationForm({ user }) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {accessToken} = useAuth();

  const ForgotPasswordSchema = Yup.object().shape({
    verificationCode: Yup.number().required("Verification code is required"),
  });

  const [err, setErr] = useState(null);

  const handleTwoStepAuth = async (verificationCode) => {
    try {
      const res = await dispatch(VerifyUser({ user, verificationCode, token: accessToken }));
      if (res.type === "auth/verify-user/fulfilled") {
        toast.success("Login successfully");
        navigate("/dashboard/app", { replace: true });
      } else if (res.type === "auth/verify-user/rejected") {
        toast.error(res.payload.response.data.error);
        setErr(
          `${
            res
              ? res.payload.response.data.error
              : "Failed to connect due to network or server error"
          }`
        );
      }
    } catch (error) {
      toast.error("Failed");
    }
  };

  const formik = useFormik({
    initialValues: {
      verificationCode: "",
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await handleTwoStepAuth(values.verificationCode);
        setSubmitting(false);
      } catch (error) {
        setErrors({ afterSubmit: error.message });
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {err !== null && <Alert severity="error">{err}</Alert>}

          <TextField
            fullWidth
            {...getFieldProps("verificationCode")}
            type="number"
            label="Verification code"
            error={Boolean(touched.verificationCode && errors.verificationCode)}
            helperText={touched.verificationCode && errors.verificationCode}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Verifiy Code
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
