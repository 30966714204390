// component
import { Divider } from "@mui/material";
import Iconify from "components/Iconify";
// ----------------------------------------------------------------------
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "redux/slices/users.slice";
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export default function NavConfig() {
  // const dispatch = useDispatch();
  const { user, accessToken } = useAuth();
  // const { users } = useSelector((state) => state.user);
  const loggedUser = user;

  // useEffect(() => {
  //   dispatch(getUsers({ accessToken }));
  // }, [dispatch, accessToken]);

  return [
    loggedUser?.role.permissions.includes("canViewDashboard") && {
      title: "Dashboard",
      path: "/dashboard/app",
      icon: getIcon("eva:pie-chart-2-fill"),
    },

    loggedUser?.role.permissions.includes("canViewAssets") && {
      title: "Assets",
      path: "/admin/assets",
      icon: getIcon("carbon:gui-management"),
      children: [
        loggedUser?.role.permissions.includes("canViewSuppliers") && {
          title: "Suppliers",
          icon: getIcon("teenyicons:appointments-outline"),
          path: "/admin/assets/suppliers",
        },
        loggedUser?.role.permissions.includes("canViewPurchases") && {
          title: "Purchases",
          icon: getIcon("teenyicons:appointments-outline"),
          path: "/admin/assets/purchases",
        },
        loggedUser?.role.permissions.includes("canViewProducts") && {
          title: "Products",
          icon: getIcon("material-symbols:inactive-order"),
          path: "/admin/assets/products",
        },
        loggedUser?.role.permissions.includes("canViewCategories") && {
          title: "Categories",
          icon: getIcon("eva:person-outline"),
          path: "/admin/assets/categories",
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewCalendar") && {
      title: "Events",
      path: "/admin/calendar",
      icon: getIcon("eva:calendar-outline"),
      children: [
        loggedUser?.role?.permissions.includes("canViewCalendar") && {
          title: "Calendar 1",
          path: "/admin/calendar/calendar",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewCalendar") && {
          title: "Calendar 2",
          path: "/admin/calendar/calendar2",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewOutMembers") && {
          title: "Out Members",
          path: "/admin/calendar/outMembers",
          icon: getIcon("eva:calendar-outline"),
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewProjects") && {
      title: "Projects",
      path: "/admin/projects",
      icon: getIcon("eva:briefcase-outline"),
    },

    loggedUser?.role.permissions.includes("canViewReceipts") && {
      title: "Receipts",
      path: "/admin/receipts",
      icon: getIcon("eva:file-text-outline"),
      children: [
        loggedUser?.role?.permissions.includes("canViewRbooks") && {
          title: "Receipt Books",
          path: "/admin/receipts/rbooks",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewReceipts") && {
          title: "Receipt Journals",
          path: "/admin/receipts/receipts",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewFmis") && {
          title: "SFMIS",
          path: "/admin/receipts/fmis",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewMdaAgents") && {
          title: "MDA Agents",
          path: "/admin/receipts/mdaAgent",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewReceiptIssues") && {
          title: "Receipt Issues",
          path: "/admin/receipts/receipt_issues/list",
          icon: getIcon("eva:calendar-outline"),
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewContract") && {
      title: "Contracts",
      path: "/admin/contracts",
      icon: getIcon("eva:briefcase-outline"),
      children: [
        loggedUser?.role.permissions.includes("canViewContract") && {
          title: "List",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/contracts/list",
        },
        loggedUser?.role.permissions.includes("canViewContract") && {
          title: "Rejected Contracts",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/contracts/rejected",
        },
        loggedUser?.role.permissions.includes("canViewContract") && {
          title: "Archived Contracts",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/contracts/archived",
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewAudits") && {
      title: "Audit Tracker",
      path: "/admin/audits",
      icon: getIcon("eva:book-open-outline"),
      children: [
        loggedUser?.role?.permissions.includes("canViewAuditAnalytics") && {
          title: "General Summary",
          path: "/admin/audits/audit-analytics",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewFinancialAudits") && {
          title: "Financial Audits",
          path: "/admin/audits/financial-audits",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewComplianceAudits") && {
          title: "Compliance Audits",
          path: "/admin/audits/compliance-audits",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewForensicAudits") && {
          title: "Forensic Audits",
          path: "/admin/audits/forensic-audits",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewPerformanceAudits") && {
          title: "Performance Audits",
          path: "/admin/audits/performance-audits",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewInformationAudits") && {
          title: "IS Audits",
          path: "/admin/audits/isa",
          icon: getIcon("eva:calendar-outline"),
        },
        loggedUser?.role?.permissions.includes("canViewSpecialAudits") && {
          title: "Special Audits",
          path: "/admin/audits/special-audits",
          icon: getIcon("eva:calendar-outline"),
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewArchive") && {
      title: "Archive",
      icon: getIcon("eva:folder-outline"),
      path: "/admin/archive",
      children: [
        loggedUser?.role.permissions.includes("canViewArchive") && {
          title: "Archive",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/archive/archives",
        },
        loggedUser?.role.permissions.includes("canViewUploads") && {
          title: "Shared",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/archive/files/shared",
        },
        loggedUser?.role.permissions.includes("canViewUploads") && {
          title: "Uploads",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/archive/files/uploads",
        },
        loggedUser?.role.permissions.includes("canViewDownloads") && {
          title: "Downloads",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/archive/files/downloads",
        },
        loggedUser?.role.permissions.includes("canViewFileManager") && {
          title: "File Manager",
          icon: getIcon("mdi:patient-outline"),
          path: "/admin/archive/fileManager",
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewHr") && {
      title: "HRM",
      path: "/admin/hr",
      icon: getIcon("eva:people-outline"),
      children: [
        loggedUser?.role.permissions.includes("canViewDepartments") && {
          title: "Departments",
          icon: getIcon("teenyicons:department-outline"),
          path: "/admin/hr/departments",
        },
        loggedUser?.role.permissions.includes("canViewEmployees") && {
          title: "Employees",
          icon: getIcon("teenyicons:people-outline"),
          path: "/admin/hr/employees",
        },
        loggedUser?.role.permissions.includes("canViewPayouts") && {
          title: "Payouts",
          icon: getIcon("material-symbols:money-outline"),
          path: "/admin/hr/payouts",
        },
        loggedUser?.role.permissions.includes("canViewLeaves") && {
          title: "Leaves",
          icon: getIcon("material-symbols:money-outline"),
          path: "/admin/hr/leaves",
        },
        loggedUser?.role.permissions.includes("canViewAttendance") && {
          title: "Attendance",
          icon: getIcon("material-symbols:attendance"),
          path: "/admin/hr/attendance",
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewAccountings") && {
      title: "Accounting",
      icon: getIcon("map:accounting"),
      path: "/admin/accounting",
      children: [
        loggedUser?.role.permissions.includes("canViewAccounts") && {
          title: "Accounts",
          path: "/admin/accounting/accounts",
        },
        loggedUser?.role.permissions.includes("canViewDeposit") && {
          title: "Deposit",
          path: "/admin/accounting/deposit",
        },
        loggedUser?.role.permissions.includes("canViewWithdrawal") && {
          title: "Withdrawal",
          path: "/admin/accounting/withdrawal",
        },
        loggedUser?.role.permissions.includes("canViewExpenses") && {
          title: "Expenses",
          path: "/admin/accounting/expenses",
        },
        loggedUser?.role.permissions.includes("canViewAccountPayable") && {
          title: "Account Payable",
          path: "/admin/accounting/accountPayable",
        },
        loggedUser?.role.permissions.includes("canViewBalanceSheet") && {
          title: "Balance Sheet",
          path: "/admin/accounting/balancesheet",
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewChats") && {
      title: "Chats",
      icon: getIcon("icon-park-outline:communication"),
      path: "/admin/chats",
    },

    loggedUser?.role.permissions.includes("canViewTrash") && {
      title: "Trash",
      icon: getIcon("bi:trash"),
      path: "/admin/trash",
      children: [
        {
          title: "Users",
          path: "/admin/trash/userTrash",
        },
        {
          title: "Files",
          path: "/admin/trash/fileTrash",
        },
      ],
    },

    loggedUser?.role.permissions.includes("canViewSettings") && {
      title: "Settings",
      icon: getIcon("simple-line-icons:settings"),
      path: "/admin/settings",
      children: [
        loggedUser?.role.permissions.includes("canViewMda") && {
          title: "MDA",
          icon: getIcon("material-symbols:prescriptions-outline"),
          path: "/admin/settings/mda",
        },
        loggedUser?.role.permissions.includes("canViewBranches") && {
          title: "Branches",
          icon: getIcon("material-symbols:prescriptions-outline"),
          path: "/admin/settings/branches",
        },
        loggedUser?.role.permissions.includes("canViewNotifications") && {
          title: "Notifications",
          icon: getIcon("eva:person-outline"),
          path: "/admin/settings/notifications",
        },
        loggedUser?.role.permissions.includes("canViewDevices") && {
          title: "Devices",
          icon: getIcon("material-symbols:computer-outline"),
          path: "/admin/settings/devices",
        },
        loggedUser?.role.permissions.includes("canViewUsers") && {
          title: "Users",
          icon: getIcon("eva:person-outline"),
          path: "/admin/settings/users",
        },
        loggedUser?.role.permissions.includes("canViewRoles") && {
          title: "Roles and Permissions",
          path: "/admin/settings/roles",
        },
        loggedUser?.role.permissions.includes("canViewSMS") && {
          title: "SMS and Mail Config",
          path: "/admin/settings/sms",
        },
        loggedUser?.role.permissions.includes("canViewSystemInfo") && {
          title: "System Config",
          path: "/admin/settings/systemInfo",
        },
        loggedUser?.role.permissions.includes("canViewLogs") && {
          title: "System Logs",
          path: "/admin/settings/logs",
        },
        loggedUser?.role.permissions.includes("canViewBackups") && {
          title: "Backups",
          path: "/admin/settings/backups",
        },
      ],
    },
    {
      title: "Logout",
      icon: getIcon("simple-line-icons:logout"),
      path: "/auth/logout",
    },
  ];
}
