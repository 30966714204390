import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  messages: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "message",
  initialState,
  reducers: {
    getMessageSuccess: (state, { payload }) => {
      state.messages = payload;
      state.loading = false;
    },
    startLoadingMessage: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllMessages = ({ accessToken, page }) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingMessage());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-messages`,
        { headers }
      );
      dispatch(slice.actions.getMessageSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

export const getMessage = async ({ accessToken }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}/get-all-messages`,
    { headers }
  );
  return res.data;
};

