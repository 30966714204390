import axios from 'axios';
import { toast } from 'react-toastify';
const API = (token) =>
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    headers: { Authorization: token },
  });
export const acessCreate = async (body, token) => {
  try {

    const { data } = await API(token).post('/chat', body);
    return data;
  } catch (error) {
    console.log('error in access create api');
  }
};
export const fetchAllChats = async (token) => {
  try {
    const { data } = await API(token).get('/chats');
    return data;
  } catch (error) {
    console.log('error in fetch all chats api');
  }
};
export const createGroup = async (body, token) => {
  try {
    const { data } = await API(token).post('group', body);
    toast.success(`${data.chatName} Group Created`);
    return data;
  } catch (error) {
    console.log('error in create group api');
  }
};
export const addToGroup = async (body, token) => {
  try {
    const { data } = await API(token).patch('groupAdd', body);
    return data;
  } catch (error) {
    console.log('error in addtogroup api');
  }
};
export const renameGroup = async (body, token) => {
  try {
    const { data } = await API(token).patch('group/rename', body);
    return data;
  } catch (error) {
    console.log('error in rename group api');
  }
};
export const removeUser = async (body, token) => {
  try {
    const { data } = await API(token).patch('groupRemove', body);
    return data;
  } catch (error) {
    console.log('error in remove user api');
  }
};
