import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "redux/slices/auth.slice";
import UserAvatar from "pages/_directory/users/List/components/UserAvatar";
import useAuth from "hooks/useAuth";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import bcrypt from "bcryptjs-react";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { LockOpen, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

export const IdleTimeOutModal = ({
  showModal,
  handleLogout,
  handleContinue,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { users } = useSelector((state) => state.user);
  const currentUser = users.data?.filter((usr) => usr?._id === user?._id)[0];
  const [showPassword, setShowPassword] = useState(false);

  let count = 1;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const password = e.target.password.value;
    if (await bcrypt.compareSync(password, currentUser?.password)) {
      localStorage.removeItem("locked");
      handleContinue();
    } else {
      toast.error("Invalid password");
      document.getElementById('unlockForm').reset();
      if (count === 3) {
        toast.error('Maximum unlock try reached.')
        await dispatch(reset());
        navigate("/auth/login", { replace: true });
      }
      count += 1;
    }
  };

  useEffect(() => {
    return (
      localStorage.setItem('locked', false)
    )
  }, []);
  return (
    <div>
      <Dialog
        open={showModal}
        // onClose={handleContinue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* <Typography sx={{ textAlign: "center" }}>
            {"System Locked ?"}
          </Typography> */}
          <div style={{ display: "flex", marginLeft: "auto" }}>
            <UserAvatar
              photo={currentUser?.photo}
              sx={{ width: "80px", height: "80px", mr:3 }}
            />
            <div
              style={{ marginTop: "auto", marginBottom: "auto", width: "100%" }}
            >
              <Typography>{currentUser?.name}</Typography>
              <Typography sx={{ color: "gray" }}>
                {currentUser?.role?.name}
              </Typography>
            </div>
            <LockIcon
              sx={{ width: "20%", height: "60px", m: "auto 0 auto 0" }}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            hey there, you have been idle or inactivity for a couple of time,{" "}
            <br />
            for your security, the system is auto-locked!
          </DialogContentText>
          <form onSubmit={handleSubmit} style={{ textAlign: "center" }} id="unlockForm">
            {/* <TextField
              required
              fullWidth
              sx={{ mt: "2%" }}
              placeholder="Enter Your Password"
              name="password"
              type={showPassword ? "text" : "password"}
              inputProps={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            /> */}
            <OutlinedInput
              fullWidth
              required
              name="password"
              placeholder="Enter Your Password"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              sx={{ mt: "2%" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Button
              startIcon={<LockOpen />}
              variant="outlined"
              type="submit"
              sx={{ mt: "2%", width: "30%" }}
            >
              Unlock
            </Button>
          </form>
        </DialogContent>

        {/* <DialogActions>
          <Button variant="outlined" onClick={handleSubmit}>
            Unlock
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};
