import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  employees: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    getEmployeeSuccess: (state, { payload }) => {
      state.employees = payload;
      state.loading = false;
    },
    startLoadingEmployee: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllEmployees= ({ accessToken, page }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingEmployee());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-employees`,
        { headers }
      );
      dispatch(slice.actions.getEmployeeSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

// export const getEmployees = async ({ accessToken }) => {
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: accessToken,
//   };
//   const res = await axios.get(
//     `${process.env.REACT_APP_BACKEND_API}/get-all-dentals`,
//     { headers }
//   );
//   return res.data;
// };
