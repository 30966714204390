import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { reset } from "redux/slices/auth.slice";
import localforage from "localforage";
import { QueryClient } from "@tanstack/react-query";

export default function LogOutDialog({
  onOpen,
  openDialog,
  onCloseDialog,
  onOpenDialog,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = new QueryClient();

  const handleLogout = async () => {
    await dispatch(reset());
    onCloseDialog(true);
    navigate("/auth/login", { replace: true });
  };

  const handleCancelDialog = () => {
    onOpen(false);
    onCloseDialog(true);
    onOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={onCloseDialog}>
      <DialogTitle>Are you sure you want to logout?</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          You will be logged out of your account.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleCancelDialog()}>Cancel</Button>
        <Button onClick={handleLogout} color="error">
          Log Out
        </Button>
      </DialogActions>
    </Dialog>
  );
}
