import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Card,
  Link,
  Container,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import useResponsive from "hooks/useResponsive";
import Page from "components/Page";
import LoginForm from "./login/LoginForm";
import LogoImg from "assets/logo.png";
import Erp from "assets/erp.png";
import loginIcon from "assets/login.ico";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { getSystemInfo } from "redux/slices/systemInfo.slice";
import Logo from "components/Logo";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme?.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme?.spacing(0, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme?.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme?.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const { user, accessToken } = useAuth();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm")); // Small screen

  const { systemInfo } = useSelector((state) => state.systemInfo);

  useEffect(() => {
    dispatch(getSystemInfo({ accessToken }));
  }, [dispatch, accessToken]);
  return (
    <Page title="Login" systemName={systemInfo?.data[0]?.name}>
      <RootStyle>
        {mdUp && (
          <>
            {/* <HeaderStyle>
              <Box
                sx={{ mt: "4%", ml: "4%", width: 160, height: 160 }}
                component="img"
                src={LogoImg}
              ></Box>
              <Box />
            </HeaderStyle> */}
            <SectionStyle>
              <div style={{ margin: "auto" }}>
                <Typography variant="h3" sx={{ px: 5, mb: 5 }}>
                  Hi, Welcome Back
                </Typography>
                <img
                  style={{ width: 300, margin: "auto" }}
                  src={LogoImg}
                  alt="logo"
                />
              </div>
            </SectionStyle>
          </>
        )}

        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ContentStyle>
            {isSmall && (
              <Logo sx={{ width: 160, height: 160, ml: "30%", mt: "0" }} />
            )}
            <Typography variant="h4" gutterBottom>
              Sign in to Office of Auditor General FRS
            </Typography>

            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              Please enter your credentials to explore your workspace!
            </Typography>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
