import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Typography,
  Badge,
} from "@mui/material";
// components
import Iconify from "components/Iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NotificationsPopover from "./NotificationPopover";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { getEvents } from "redux/slices/eventSlice";
import { isSameDay } from "date-fns";
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme?.spacing(0, 5),
  },
}));
// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { user, accessToken } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countChat, setCountChat] = useState(0);
  const { events } = useSelector((state) => state.event);
  const { notifications } = useSelector((state) => state.chats);

  let filteredEvents = [];

  if (!user?.role?.permissions?.includes("canViewAllEvents")) {
    filteredEvents = events.events?.filter((event) => {
      return (
        event.members.includes(user.username) ||
        event.createdBy === user.username
      );
    });
  } else {
    filteredEvents = events.events;
  }

  const todaysEventsAppointments = filteredEvents?.filter((event) => {
    const startDate = new Date(event.startDate);
    const endDate = new Date(event.endDate);

    // Check if the event's type is 'Event' or 'Appointment' and if it occurs today
    return (
      (event.type === "Event" || event.type === "Appointment") &&
      (isSameDay(startDate, new Date()) ||
        isSameDay(endDate, new Date()) ||
        (startDate < new Date() && endDate > new Date())) // This line checks if the current date falls within a multi-day event
    );
  });

  // useEffect(() => {
  //   let socket = io(process.env.REACT_APP_ENDPOINT);
  //   socket?.on("message recieved", (newMessageRecieved) => {
  //     setCountChat(1);
  //   });
  // });

  useEffect(() => {
    dispatch(getEvents({ accessToken }));
  }, [dispatch, accessToken]);

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <Badge badgeContent={notifications?.length} color="error">
            <IconButton
              color="default"
              onClick={() => {
                navigate("/admin/chats");
              }}
              sx={{ width: 40, height: 40 }}
            >
              <Iconify icon="eva:message-square-outline" />
            </IconButton>
          </Badge>
          {user?.role?.permissions?.includes("canViewCalendar") && (
            <Badge
              badgeContent={todaysEventsAppointments?.length}
              color="error"
            >
              <IconButton
                color="default"
                onClick={() => {
                  navigate("/admin/calendar/calendar");
                }}
                sx={{ width: 40, height: 40 }}
              >
                <Iconify icon="eva:calendar-outline" />
              </IconButton>
            </Badge>
          )}

          <NotificationsPopover />
          <p />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
