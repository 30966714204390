import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  expenses: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  bulkExpenses: [],

  loading: false,
  error: null,
};

const slice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    getExpensesSuccess: (state, action) => {
      state.expenses = action.payload;
      state.loading = false;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    loadBulkExpenses: (state, action) => {
      state.bulkExpenses = action.payload;
    },
    removeBulkExpense: (state, action) => {
      state.bulkExpenses = action.payload;
    },
  },
});

export const { loadBulkExpenses, removeBulkExpense } = slice.actions;

export default slice.reducer;

export const getExpenses = ({ accessToken, page, limit }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/expenses`,
        { headers }
      );
      dispatch(slice.actions.getExpensesSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

export const getAllExpenses = async ({ accessToken }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  const res = await axios.get(
    `${process.env.REACT_APP_BACKEND_API}/expenses`,
    { headers }
  );
  return res.data;
};
