import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import defaultLogo from "assets/icon.png";
import { getSystemInfo } from "redux/slices/systemInfo.slice";
import useAuth from "hooks/useAuth";
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const { systemInfo } = useSelector((state) => state.systemInfo);
  const { accessToken } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSystemInfo({ accessToken }));
  }, [accessToken, dispatch]);

  const logo = (
    <Box
      sx={{ mt:'8%', mb:'8%', ...sx }}
      component="img"
      src={ defaultLogo}
    ></Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
